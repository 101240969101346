import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  TextField,
  Grid,
  Button,
  Alert,
  Stack,
  CircularProgress,
} from "@mui/material";

const Select = styled("select")({
  fontSize: "1rem",
  color: "#fff",
  backgroundColor: "#2D2D2D",
  border: "1px solid transparent",
  borderRadius: "4px",
  width: "100%",
  height: "3.5rem",
  padding: "calc(0.5em - 1px) calc(0.75em - 1px)",
  boxShadow: "inset 0 0.0625em 0.125em rgb(10 10 10 / 5%)",
  marginTop: "16px", // Margen superior al select
});

export default function FormRegistro() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    dni: "",
    username: "",
    email: "",
    password: "",
    tel: "",
    phone: "",
    pais: "Colombia",
    ciudad: "Medellín",
    direccion: "",
    products: { id: 1 },
    id_corredor: 0,
  });

  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  // Función para generar una cadena aleatoria basada en el nombre
  const generateRandomString = (name) => {
    const nameParts = name.split(" ");
    const randomNum = Math.floor(Math.random() * 100);
    return nameParts.length > 0 ? nameParts[0] + randomNum : "user" + randomNum;
  };

  useEffect(() => {
    let username = data.email.split("@")[0];
    if (username.length < 3) {
      username += generateRandomString(data.name);
    }
    setData((prevData) => ({ ...prevData, username }));
  }, [data.email, data.name]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar que la contraseña tenga al menos 6 caracteres
    if (data.password.length < 6) {
      setAlert({
        show: true,
        type: "error",
        message: "La contraseña debe tener al menos 6 caracteres.",
      });
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/local/register`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN_EVENT}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const result = await response.json();
      if (response.status === 200) {
        setAlert({
          show: true,
          type: "success",
          message: "Te has registrado exitosamente.",
        });
      } else {
        setAlert({ show: true, type: "error", message: result.error.message });
      }
    } catch (error) {
      setAlert({ show: true, type: "error", message: "Error en el registro." });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {[
            { label: "Nombre completo", name: "name", type: "text" },
            { label: "DNI", name: "dni", type: "text" },
            { label: "Email", name: "email", type: "email" },
            { label: "Contraseña", name: "password", type: "password" },
            { label: "Número telefónico", name: "tel", type: "text" },
            { label: "Número Celular", name: "phone", type: "text" },
          ].map((field, index) => (
            <Grid key={index} md={6} xs={12} sx={{ marginBottom: 0.5 }}>
              <TextField
                fullWidth
                variant="outlined"
                label={field.label}
                type={field.type}
                required
                value={data[field.name]}
                onChange={(e) =>
                  setData({ ...data, [field.name]: e.target.value })
                }
                sx={{ marginBottom: 0.5 }}
              />
            </Grid>
          ))}

          <Grid md={6} xs={12}>
            <Select
              required
              value={data.pais}
              onChange={(e) => setData({ ...data, pais: e.target.value })}
              style={{marginTop: "0"}}
            >
              {[
                "Argentina",
                "Bolivia",
                "Chile",
                "Colombia",
                "Costa Rica",
                "Cuba",
                "República Dominicana",
                "Ecuador",
                "España",
                "Guatemala",
                "Honduras",
                "México",
                "Nicaragua",
                "Panamá",
                "Paraguay",
                "Perú",
                "Puerto Rico",
                "Uruguay",
                "Venezuela",
                "Estados Unidos",
                "Filipinas",
              ].map((pais) => (
                <option key={pais} value={pais}>
                  {pais}
                </option>
              ))}
            </Select>
          </Grid>

          <Grid md={6} xs={12} sx={{ marginBottom: 0.5 }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Dirección"
              type="text"
              required
              value={data.direccion}
              onChange={(e) =>
                setData({ ...data, direccion: e.target.value })
              }
              sx={{ marginBottom: 0.5 }}
            />
          </Grid>
        </Grid>

        <Stack direction="row" spacing={2} sx={{ marginTop: 0.5 }}>
          <Button type="submit" variant="contained" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Registrarse"}
          </Button>
        </Stack>
      </form>

      {alert.show && (
        <Alert severity={alert.type} sx={{ marginTop: 0.5 }}>
          {alert.message}
        </Alert>
      )}
    </>
  );
}
