import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { Chip } from "@mui/material";

const options = {
  responsive: "vertical",
  selectableRows: "none",
  search: true,
  filter: true,
  viewColumns: false,
  print: false,
  download: true,
  pagination: true,
  rowsPerPage: 5,
  rowsPerPageOptions: [5],
};

const OperationTable = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Consulta usuarios asociados a tu ID como partner
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/users?filters[partner][$eq]=${Cookies.get("id")}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwt")}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const usersData = response.data;
          const usuariosSimplificados = usersData.map((user) => ({
            name: user.username,
            email: user.email,
            createdAt: moment(user.createdAt).format("DD-MM-yyyy"),
            confirmedAdmin: user.confirmed_admin,
          }));

          setUsers(usuariosSimplificados);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const data = users.map((user) => [
    user.name,
    user.email,
    user.createdAt,
    user.confirmedAdmin ? (
      <Chip label="Confirmado" color="success" />
    ) : (
      <Chip label="No confirmado" color="error" />
    ),
  ]);

  return (
    <MUIDataTable
      title={"Usuarios Asociados"}
      data={data}
      columns={["Nombre", "Correo", "Fecha de Creación", "Estado de Confirmación"]}
      options={options}
    />
  );
};

export default OperationTable;
