// OperationTable.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import MUIDataTable from "mui-datatables";
import moment from "moment";

const options = {
  responsive: "vertical",
  selectableRows: "none",
  search: true,
  filter: true,
  viewColumns: false,
  print: false,
  download: true,
  pagination: true,
  rowsPerPage: 5,
  rowsPerPageOptions: [5],
};

const OperationTable = () => {
  const [operations, setOperations] = useState([]);

  useEffect(() => {
    // Consulta operaciones
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/operations?filters[user][id][$eq]=${Cookies.get(
          "id"
        )}&populate=*&pagination[pageSize]=1000000000`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwt")}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const operat = response.data.data;
          const operacionesSimplificadas = operat.map((o) => ({
            id: o.id,
            amount: `$${o.attributes.amount}`,
            currency: o.attributes.currency,
            type: o.attributes.type.data.attributes.name === "Deposito" ? "DEPOSITO" : "RETIRO",
            verify: o.attributes.verify ? "Confirmado" : "No confirmado",
            date: o.attributes.app_date ? moment(o.attributes.app_date).format("DD-MM-yyyy") : "",
            time: o.attributes.app_time
              ? moment(o.attributes.app_time, "HH:mm:ss.SSS").format("hh:mm:ss a")
              : "",
          }));

          setOperations(operacionesSimplificadas);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const data = operations.map((operation) => [
    operation.amount,
    operation.currency,
    operation.type,
    operation.verify,
    operation.date,
    operation.time,
  ]);

  return (
    <MUIDataTable
      title={"Historial de Operaciones"}
      data={data}
      columns={["Monto", "Moneda", "Tipo", "Verificado", "Fecha", "Hora"]}
      options={options}
    />
  );
};

export default OperationTable;
