import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Paper,
  Typography,
  styled,
  Chip,
  Button,
  Stack,
} from "@mui/material";

import { TickerTape, MarketOverview } from "react-ts-tradingview-widgets";

import InfoIcon from "@mui/icons-material/Info";

import ChartBalance from "./Components/ChartBalance";
import ChartPercentProfits from "./Components/ChartPercentProfits";
import Investment from "./Components/Investment";
import "./Components/styleCard.css";

import cardBidbank from "../../../static/img/card-bidbank.webp";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: "#fff",
  borderRadius: 20,
}));

const style = {
  parent: {
    display: "none",
  },
};

export default function Fondo() {
  const [balance, setBalance] = useState(0);
  const [gananciaFondo, setGananciaFondo] = useState(0);

  const [profitPercent, setProfitPercent] = useState({ mes: "Mes", profit: 0 });
  const [zoom, setZoom] = useState(false);

  const dataUser = {
    id: Cookies.get("id"),
    jwt: Cookies.get("jwt"),
  };

  const fetchData = useCallback(async () => {
    try {
      const balanceRes = await axios.post(
        `${process.env.REACT_APP_API_SIGNALS_URL}/api/auth/consulta_balance`,
        dataUser
      );
      if (balanceRes.status === 200) {
        setBalance(balanceRes.data.balanceTotal);
      }

      const profitRes = await axios.post(
        `${process.env.REACT_APP_API_SIGNALS_URL}/api/auth/consulta_profit`,
        dataUser
      );
      if (profitRes.status === 200) {
        setProfitPercent(profitRes.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const constrain = 10;

  const handleMouseMove = (e) => {
    const element = document.getElementById("card3d");
    const box = element.getBoundingClientRect();
    const calcX = -(e.clientY - box.y - box.height / 2) / constrain;
    const calcY = (e.clientX - box.x - box.width / 2) / constrain;

    element.style.transform = `perspective(1000px) rotateX(${calcX}deg) rotateY(${calcY}deg)`;
  };

  const resetTransform = () => {
    const element = document.getElementById("card3d");
    element.style.transform = `perspective(1000px) rotateX(0deg) rotateY(0deg)`;
  };

  useEffect(() => {
    const element = document.getElementById("card3d");
    element.addEventListener("mousemove", handleMouseMove);
    element.addEventListener("mouseleave", resetTransform);

    // Cleanup event listeners on unmount
    return () => {
      element.removeEventListener("mousemove", handleMouseMove);
      element.removeEventListener("mouseleave", resetTransform);
    };
  }, []);

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: 0, paddingBottom: 5 }}>
        <Grid xs={12} md={12}>
          <TickerTape colorTheme="light" copyrightStyles={style}></TickerTape>
        </Grid>

        <Grid xs={12} md={8}>
          <Item>
            <Box>
              <Grid container alignItems="flex-end">
                <Grid xs={6}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ color: "#1d575b", fontSize: 35, fontWeight: "bold" }}
                  >
                    Balance
                  </Typography>
                </Grid>

                <Grid xs={6} sx={{ textAlign: "right" }}>
                  <Chip
                    label={`${profitPercent.profit}% ${profitPercent.mes}`}
                    sx={{
                      borderRadius: 1,
                      color: "#fff",
                      fontSize: 15,
                      backgroundColor: "#ffb95f",
                    }}
                    icon={<InfoIcon sx={{ color: "#fff" }} />}
                  />
                </Grid>

                <Grid xs={12}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    sx={{ color: "#4b4b4b", fontWeight: 700 }}
                  >
                    {balance} USD
                  </Typography>
                </Grid>

                <Grid xs={12}>
                  <ChartBalance zoom={zoom} />
                </Grid>

                <Grid xs={12}>
                  <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={() => setZoom(false)}>
                      -
                    </Button>
                    <Button variant="contained" onClick={() => setZoom(true)}>
                      +
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Item>
        </Grid>

        <Grid xs={12} md={4}>
          <div className="container">
            <div id="card3d" className="card-3d">
              <img src={cardBidbank} alt="Card Bidbank" />
              <div className="datos-card">
                <p>Ganancia total: {gananciaFondo} USD</p>
                <p>Balance total: {balance} USD</p>
              </div>
            </div>
          </div>

          <Item>
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ color: "#1d575b", fontSize: 25, fontWeight: "bold" }}
            >
              Porcentaje por Mes
            </Typography>
            <ChartPercentProfits />
          </Item>
        </Grid>

        <Grid xs={12} md={5}>
          <MarketOverview
            colorTheme="light"
            copyrightStyles={style}
            height={350}
            width="100%"
          ></MarketOverview>
        </Grid>

        <Grid xs={12} md={7}>
          <Item>
            <Investment setGananciaFondo={setGananciaFondo} />
          </Item>
        </Grid>
      </Grid>
    </>
  );
}
