import { Box, styled, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import imgAgro from "../../../static/img/alternativas-inversion/agro.png";
import imgInmobiliaria from "../../../static/img/alternativas-inversion/inmobiliaria.png";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: "#1F1F1F",
  color: "#fff",
  margin: "0.5rem",
  borderRadius: "15px !important",
  paddingTop: "5rem",
  paddingLeft: "1rem",
  paddingBottom: "1rem",
}));

export default function AlternativaInversion() {
  return (
    <>
        <Grid container spacing={2}>

          <Grid md={4} xs={12}>
            <Item
              sx={{
                backgroundImage: `url("${imgAgro}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "unset",
                backgroundPosition: "left",
                cursor: "pointer",
              }}
            >
              <Typography gutterBottom variant="h5" component="div">
                Casillero de inversión
              </Typography>
              <Typography variant="h6" color="white">
                BID1
              </Typography>
            </Item>
          </Grid>

          <Grid md={4} xs={12}>
            <Item
              sx={{
                backgroundImage: `url("${imgInmobiliaria}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "unset",
                backgroundPosition: "left",
                cursor: "pointer",
              }}
            >
              <Typography gutterBottom variant="h5" component="div">
                Casillero de inversión
              </Typography>
              <Typography variant="h6" color="white">
                BID2
              </Typography>
            </Item>
          </Grid>

        </Grid>
    </>
  );
}
