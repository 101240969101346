import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  TextField,
  Grid,
  Button,
  Alert,
  Stack,
  CircularProgress,
} from "@mui/material";
import Cookies from "js-cookie";

const Select = styled("select")({
  fontSize: "1rem",
  color: "#fff",
  backgroundColor: "#2D2D2D",
  border: "1px solid transparent",
  borderRadius: "4px",
  width: "100%",
  height: "3.5rem",
  padding: "calc(0.5em - 1px) calc(0.75em - 1px)",
  boxShadow: "inset 0 0.0625em 0.125em rgb(10 10 10 / 5%)",
  marginTop: "16px",
});

export default function FormRegistro() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    dni: "",
    username: "",
    email: "",
    password: "",
    tel: "",
    phone: "",
    pais: "Colombia",
    ciudad: "Medellín",
    direccion: "",
    products: { id: 1 },
    id_corredor: 0,
    capital_inicial: 0,
    image: null,
    partner: Cookies.get("id"),
  });

  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  const generateRandomString = (name) => {
    const nameParts = name.split(" ");
    const randomNum = Math.floor(Math.random() * 100);
    return nameParts.length > 0 ? nameParts[0] + randomNum : "user" + randomNum;
  };

  useEffect(() => {
    let username = data.email.split("@")[0];
    if (username.length < 3) {
      username += generateRandomString(data.name);
    }
    setData((prevData) => ({ ...prevData, username }));
  }, [data.email, data.name]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      // Paso 1: Registrar el usuario
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/local/register`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${Cookies.get("jwt")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
  
      const dataRes = await response.json();
  
      if (response.ok) {
        setAlert({
          show: true,
          type: "success",
          message: "Te has registrado exitosamente.",
        });
  
        // Paso 2: Crear FormData para subir archivos
        if (data.image) {
          const formDataFiles = new FormData();
  
          formDataFiles.append(
            "data",
            JSON.stringify({
              null: null,
            })
          );
  
          formDataFiles.append("files.archivos", data.image);
  
          // Subir archivo a la colección `files-users` u otra
          const response_files = await fetch(
            `${process.env.REACT_APP_API_URL}/api/files-users`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${Cookies.get("jwt")}`,
              },
              body: formDataFiles,
            }
          );
  
          const dataResFiles = await response_files.json();
          console.log("Respuesta de subida de archivos:", dataResFiles.data.id);
  
          if (response_files.ok) {
            // Paso 3: Asociar el archivo subido al usuario
            const dataFilesUser = {
              files_users: dataResFiles.data.id,
            };
  
            await fetch(
              `${process.env.REACT_APP_API_URL}/api/users/${dataRes.user.id}`,
              {
                method: "PUT",
                headers: {
                  Authorization: `Bearer ${Cookies.get("jwt")}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(dataFilesUser),
              }
            );
          } else {
            console.log("Error al subir el archivo:", dataResFiles);
          }
        }
      } else {
        setAlert({
          show: true,
          type: "error",
          message: dataRes.error.message,
        });
      }
    } catch (error) {
      setAlert({ show: true, type: "error", message: "Error en el registro." });
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleFileChange = (e) => {
    setData({ ...data, image: e.target.files[0] });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {[
            { label: "Nombre completo", name: "name", type: "text" },
            { label: "Email", name: "email", type: "email" },
            { label: "Contraseña", name: "password", type: "password" },
            { label: "Número telefónico", name: "tel", type: "text" },
            {
              label: "Capital inicial",
              name: "capital_inicial",
              type: "number",
            },
          ].map((field, index) => (
            <Grid key={index} md={6} xs={12} sx={{ marginBottom: 0.5 }}>
              <TextField
                fullWidth
                variant="outlined"
                label={field.label}
                type={field.type}
                required
                value={data[field.name]}
                onChange={(e) =>
                  setData({ ...data, [field.name]: e.target.value })
                }
                sx={{ marginBottom: 0.5 }}
              />
            </Grid>
          ))}

          <Grid md={6} xs={12}>
            <Select
              required
              value={data.pais}
              onChange={(e) => setData({ ...data, pais: e.target.value })}
              sx={{ marginTop: 0 }}
            >
              {[
                "Argentina",
                "Bolivia",
                "Chile",
                "Colombia",
                "Costa Rica",
                "Cuba",
                "República Dominicana",
                "Ecuador",
                "España",
                "Guatemala",
                "Honduras",
                "México",
                "Nicaragua",
                "Panamá",
                "Paraguay",
                "Perú",
                "Puerto Rico",
                "Uruguay",
                "Venezuela",
                "Estados Unidos",
                "Filipinas",
              ].map((pais) => (
                <option key={pais} value={pais}>
                  {pais}
                </option>
              ))}
            </Select>
          </Grid>

          <Grid md={12} xs={12} sx={{ marginBottom: 0.5 }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Dirección"
              type="text"
              required
              value={data.direccion}
              onChange={(e) => setData({ ...data, direccion: e.target.value })}
              sx={{ marginBottom: 0.5 }}
            />
          </Grid>

          {/* Campo para cargar imagen */}
          <Grid md={6} xs={12} sx={{ marginBottom: 0.5 }}>
            <input
              accept="image/*"
              type="file"
              onChange={handleFileChange}
              style={{ display: "block", marginTop: "16px" }}
            />
          </Grid>
        </Grid>

        <Stack direction="row" spacing={2} sx={{ marginTop: 0.5 }}>
          <Button type="submit" variant="contained" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Registrarse"}
          </Button>
        </Stack>
      </form>

      {alert.show && (
        <Alert severity={alert.type} sx={{ marginTop: 0.5 }}>
          {alert.message}
        </Alert>
      )}
    </>
  );
}
