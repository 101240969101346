import React, { useState } from "react";
import PropTypes from "prop-types";
import { Outlet, Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Modal,
} from "@mui/material";

import Logo from "../../static/img/logo.png";
import { HiOutlineChartBar } from "react-icons/hi";
import { VscCircleLarge } from "react-icons/vsc";
import { BsCoin } from "react-icons/bs";

import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";

import SessionFail from "../../components/SessionFail";

import AccountForm from "./components/AccountForm";

const drawerWidth = 230;

function DashboardPage({ window, sessionActive, setSessionActive }) {
  const [keyMenu, setKeyMenu] = useState(0);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openModalAccount, setOpenModalAccount] = useState(null);

  const handleModalToggleAccount = () => {
    setOpenModalAccount(!openModalAccount);
  };

  const dataMenu = [
    { icon: <HiOutlineChartBar />, label: "Home", link: "/dashboard/home" },
    {
      icon: <BsCoin />,
      label: "Funds",
      link: "/dashboard/funds",
    },
    {
      icon: <VscCircleLarge />,
      label: "Alternativa Inversión",
      link: "/dashboard/alternativa-inversion",
    },
    {
      icon: <VscCircleLarge />,
      label: "Partners",
      link: "/dashboard/partners",
    },
  ];

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const handleMenuClick = (key) => setKeyMenu(key);

  const drawer = (
    <div>
      <Toolbar>
        <Box m="auto" textAlign={"center"}>
          <img src={Logo} alt="Logo" style={{ width: "40%" }} />
        </Box>
      </Toolbar>
      <List>
        {dataMenu.map((item, key) => (
          <ListItem
            key={item.label}
            disablePadding
            onClick={() => handleMenuClick(key)}
          >
            <Link
              to={item.link}
              style={{ textDecoration: "none", width: "100%" }}
            >
              <ListItemButton
                sx={{
                  backgroundColor: key === keyMenu ? "#fff" : null,
                  color: key === keyMenu ? "#4b4b4b" : "#fff",
                  mx: 1,
                  my: 0.5,
                  borderRadius: key === keyMenu ? "8px" : null,
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                    color: "#4b4b4b",
                    borderRadius: "8px",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color: key === keyMenu ? "#ffb95f" : "#ffb95f",
                    fontSize: 25,
                    "&:hover": {
                      color: "#ffb95f",
                    },
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const navigate = useNavigate();

  const logout = () => {
    Cookies.remove("jwt");
    Cookies.remove("id");
    setSessionActive(false);
    navigate("/login");
  };

  return sessionActive ? (
    <>
      <Modal
        open={!!openModalAccount}
        onClose={() => setOpenModalAccount(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AccountForm setOpenModalAccount={setOpenModalAccount} />
      </Modal>

      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            backgroundColor: "#1d575b",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: { sm: "none" },
                backgroundColor: "#fff",
              }}
            >
              <MenuIcon />
            </IconButton>

            <Box sx={{ flexGrow: 1 }} />
            <IconButton>
              <AccountCircle
                onClick={() => handleModalToggleAccount()}
                sx={{ color: "#fff" }}
              />
            </IconButton>
            <IconButton onClick={logout}>
              <LogoutIcon sx={{ color: "#fff" }} />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                backgroundColor: "#1d575b", // Aquí el color negro
                background: "linear-gradient(180deg, #1d575b 0%, #092f32 100%)",
                zIndex: 99999,
              },
            }}
          >
            {drawer}
          </Drawer>

          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                backgroundColor: "#1d575b", // Aquí el color negro
                background: "linear-gradient(180deg, #1d575b 0%, #092f32 100%)", 
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </>
  ) : (
    <SessionFail />
  );
}

DashboardPage.propTypes = {
  window: PropTypes.func,
};

export default DashboardPage;
