import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import Cookies from "js-cookie";
import io from "socket.io-client";
import MUIDataTable from "mui-datatables";

import Grid from "@mui/material/Unstable_Grid2";
import { Box, Typography, Divider, Button, Modal } from "@mui/material";

const options = {
  responsive: "vertical",
  selectableRows: "none",
  search: true,
  filter: true,
  viewColumns: false,
  print: false,
  download: true,
  pagination: true,
  rowsPerPage: 5,
  rowsPerPageOptions: [5],
};

export default function Investment({setGananciaFondo}) {
  const [ganancia, setGanancia] = useState("calculando");
  const [capitalInicial, setCapitalInicial] = useState(0);
  const [fechaInicial, setFechaInicial] = useState("");
  const [balance, setBalance] = useState(0);
  const [operations, setOperations] = useState([]);
  const [totalRetiros, setTotalRetiros] = useState("calculando");
  const [data1, setData1] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const dataUser = {
    id: Cookies.get("id"),
    jwt: Cookies.get("jwt"),
  };

  useEffect(() => {
    // Consulta balance
    axios
      .post(
        `${process.env.REACT_APP_API_SIGNALS_URL}/api/auth/consulta_balance`,
        dataUser
      )
      .then((response) => {
        if (response.status === 200) {
          setGanancia(response.data.ganancia_acumulada);
          setBalance(response.data.balanceTotal);
          setCapitalInicial(response.data.capitalInicial);
          setFechaInicial(response.data.fecha_inversion);
          setGananciaFondo(response.data.ganancia_acumulada);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // Consulta operaciones
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/operations?filters[user][id][$eq]=${Cookies.get(
          "id"
        )}&populate=*&pagination[pageSize]=1000000000`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwt")}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          let operat = response.data.data;

          const operacionesSimplificadas = operat.map((o) => ({
            id: o.id,
            amount: o.attributes.amount,
            currency: o.attributes.currency,
            type: o.attributes.type.data.attributes.name,
            product: o.attributes.product.data.id,
            user: o.attributes.user.data.id,
            date: o.attributes.date,
            verify: o.attributes.verify,
            app_date: o.attributes.app_date,
            app_time: o.attributes.app_time,
          }));

          setOperations(operacionesSimplificadas);

          // Calcular total de retiros confirmados
          const totalRetirosConfirmados = operacionesSimplificadas
            .filter((op) => op.type === "Retiro" && op.verify === true)
            .reduce((sum, op) => sum + parseFloat(op.amount), 0);
          setTotalRetiros(totalRetirosConfirmados);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const socket = io(`${process.env.REACT_APP_API_SIGNALS_URL}`);

    socket.on("signal_balance", function (data) {
      axios
        .post(
          `${process.env.REACT_APP_API_SIGNALS_URL}/api/auth/consulta_balance`,
          dataUser
        )
        .then((response) => {
          if (response.status === 200) {
            setGanancia(response.data.ganancia_acumulada);
            setBalance(response.data.balanceTotal);
            setCapitalInicial(response.data.capitalInicial);
            setFechaInicial(response.data.fecha_inversion);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/api/operations?filters[user][id][$eq]=${Cookies.get(
            "id"
          )}&populate=*&pagination[pageSize]=1000000000`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwt")}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            let operat = response.data.data;

            const operacionesSimplificadas = operat.map((o) => ({
              id: o.id,
              amount: o.attributes.amount,
              currency: o.attributes.currency,
              type: o.attributes.type.data.attributes.name,
              product: o.attributes.product.data.id,
              user: o.attributes.user.data.id,
              date: o.attributes.date,
              verify: o.attributes.verify,
              app_date: o.attributes.app_date,
              app_time: o.attributes.app_time,
            }));

            setOperations(operacionesSimplificadas);

            // Recalcular total de retiros confirmados
            const totalRetirosConfirmados = operacionesSimplificadas
              .filter((op) => op.type === "Retiro" && op.verify === true)
              .reduce((sum, op) => sum + parseFloat(op.amount), 0);
            setTotalRetiros(totalRetirosConfirmados);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }, []);

  const openModal = () => {
    setModalOpen(true);

    const data2 = operations.map((operation) => [
      `$${operation.amount}`,
      operation.currency,
      operation.type === "Deposito" ? "DEPOSITO" : "RETIRO",
      operation.verify === true ? "Confirmado" : "No confirmado",
      operation.app_date ? moment(operation.app_date).format("DD-MM-yyyy") : "",
      operation.app_time
        ? moment(operation.app_time, "HH:mm:ss.SSS").format("hh:mm:ss a")
        : "",
    ]);

    setData1(data2);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Box>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ color: "#1d575b", fontSize: 25, fontWeight: "bold" }}
        >
          Investment
        </Typography>

        <Grid container alignItems="flex-end">
          <Grid xs={6} md={8}>
            <Box>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ color: "#4b4b4b", fontSize: 18 }}
              >
                Fecha de inversión inicial:
              </Typography>
            </Box>
          </Grid>
          <Grid xs={6} md={4} sx={{ textAlign: "right" }}>
            <Box>
              <Typography
                variant="body1"
                gutterBottom
                sx={{ color: "#4b4b4b", fontSize: 18 }}
              >
                {fechaInicial
                  ? moment(fechaInicial).format("DD-MM-yyyy")
                  : "Fecha"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ backgroundColor: "#dedede" }} />

        <Grid container alignItems="flex-end">
          <Grid xs={6} md={6}>
            <Box>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ color: "#4b4b4b", fontSize: 18 }}
              >
                Depósito inicial:
              </Typography>
            </Box>
          </Grid>
          <Grid xs={6} md={6} sx={{ textAlign: "right" }}>
            <Box>
              <Typography
                variant="body1"
                gutterBottom
                sx={{ color: "#4b4b4b", fontSize: 18 }}
              >
                {capitalInicial === 0 ? "Calculando" : `${capitalInicial} USD`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ backgroundColor: "#dedede" }} />

        <Grid container alignItems="flex-end">
          <Grid xs={6} md={6}>
            <Box>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ color: "#4b4b4b", fontSize: 18 }}
              >
                Ganancia total:
              </Typography>
            </Box>
          </Grid>
          <Grid xs={6} md={6} sx={{ textAlign: "right" }}>
            <Box>
              <Typography
                variant="body1"
                gutterBottom
                sx={{ color: "#4b4b4b", fontSize: 18 }}
              >
                {ganancia === "calculando" ? "calculando" : `${ganancia} USD`}

              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ backgroundColor: "#dedede" }} />

        <Grid container alignItems="flex-end">
          <Grid xs={6} md={6}>
            <Box>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ color: "#4b4b4b", fontSize: 18 }}
              >
                Total Retiros:
              </Typography>
            </Box>
          </Grid>
          <Grid xs={6} md={6} sx={{ textAlign: "right" }}>
            <Box>
              <Typography
                variant="body1"
                gutterBottom
                sx={{ color: "#4b4b4b", fontSize: 18 }}
              >
                {totalRetiros === "calculando"
                  ? "Calculando retiros"
                  : `${totalRetiros} USD`}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ backgroundColor: "#dedede" }} />

        <Grid container alignItems="flex-end">
          <Grid xs={12} md={5} sx={{ textAlign: "left" }}>
            <Box>
              {totalRetiros === "calculando" ? (
                <Button variant="contained" disabled onClick={openModal}>
                  Cargando Historial
                </Button>
              ) : (
                <Button variant="contained" onClick={openModal}>
                  Historial de operaciones
                </Button>
              )}
            </Box>
          </Grid>
          <Grid xs={12} md={7} sx={{ textAlign: "right" }}>
            <Box>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ color: "#4b4b4b", fontWeight: 700 }}
              >
                {balance === 0 ? "Calculando" : `Balance total: ${balance} USD`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 900,
            maxHeight: "100vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            borderRadius: 5,
            boxShadow: 24,
            p: 2,
          }}
        >
          <MUIDataTable
            title={"Historial"}
            data={data1}
            columns={["Monto", "Moneda", "Tipo", "Verificado", "Fecha", "Hora"]}
            options={{
              ...options,
              responsive: "vertical",
              textLabels: {
                body: {
                  noMatch: "No tienes depositos/retiros",
                },
              },
            }}
          />
        </Box>
      </Modal>
    </>
  );
}
