import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { styled } from "@mui/material/styles";
import { Button, Typography, Paper } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import FormRegistro from "./components/FormRegistro";
import UserPartnersTable from "./components/UserPartnersTable";
import ChartSaldoPartner from "./components/ChartSaldoPartner";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  borderRadius: 20,
}));

export default function Partners() {
  const [saldo, setSaldo] = useState("calculando");

  useEffect(() => {
    const fetchSaldo = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`, {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwt")}`,
          },
        });
        const userSaldo = response.data.saldo; // Ajusta el nombre del campo si es diferente
        setSaldo(userSaldo);
        console.log(response.data);
        
      } catch (error) {
        console.error("Error fetching saldo:", error);
      }
    };

    fetchSaldo();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} md={12}>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ color: "#444", fontSize: 35, fontWeight: "bold" }}
          >
            Partners Club
          </Typography>
        </Grid>

        <Grid xs={12} md={6}>
          <Item>
            <h2 className="is-size-1 has-text-weight-light">
              Saldo: <strong className="has-text-dark">{saldo === "calculando" ? "calculando" : `${saldo} USD`}</strong>
              
            </h2>
            <Button
              variant="outlined"
              sx={{
                borderRadius: "30px",
                borderColor: "#444",
                color: "#444",
                marginTop: "2rem",
              }}
            >
              Convertirse en Partner
            </Button>
          </Item>

          <Item sx={{marginTop: 2}}>
          <ChartSaldoPartner/>
          </Item>
          
        </Grid>

        <Grid xs={12} md={6}>
          <Item>
            <Typography
              variant="h4"
              sx={{
                color: "#1d575b",
                fontWeight: "bold",
                marginBottom: 3,
                textAlign: "center",
                fontFamily: "'Roboto', sans-serif",
              }}
            >
              Registrar un asociado
            </Typography>

            <div className="p-4">
              <FormRegistro />
            </div>
          </Item>
        </Grid>

        <Grid xs={12} md={12}>
          <UserPartnersTable />
        </Grid>
      </Grid>
    </>
  );
}
