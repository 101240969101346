import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";

import {
  Box,
  Typography,
  styled,
  List,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Modal,
} from "@mui/material";

import DepositoForm from "./components/DepositoForm";
import RetiroForm from "./components/RetiroForm";
import OperationTable from "./components/OperationTable";

import Tether from "../../../static/icons/tether.png";
import Bnb from "../../../static/icons/bnb.png";
import Btc from "../../../static/icons/btc.png";
import Banco from "../../../static/icons/banco.png";
import Eth from "../../../static/icons/eth.png";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  color: theme.palette.text.secondary,
  padding: "1rem",
  borderRadius: 20,
}));

const Funds = () => {
  const dataUser = {
    id: Cookies.get("id"),
    jwt: Cookies.get("jwt"),
  };

  const [balance, setBalance] = useState(0);

  const [openModalDeposito, setOpenModalDeposito] = useState(null);
  const [openModalRetiro, setOpenModalRetiro] = useState(null);

  const handleModalToggleDeposito = (type) => {
    setOpenModalDeposito(type);
  };

  const handleModalToggleRetiro = (type) => {
    setOpenModalRetiro(type);
  };

  const fetchData = useCallback(async () => {
    try {
      const balanceRes = await axios.post(
        `${process.env.REACT_APP_API_SIGNALS_URL}/api/auth/consulta_balance`,
        dataUser
      );
      if (balanceRes.status === 200) {
        setBalance(balanceRes.data.balanceTotal);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Modal
        open={!!openModalDeposito}
        onClose={() => setOpenModalDeposito(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DepositoForm
          tipoDeposito={openModalDeposito}
          setOpenModalDeposito={setOpenModalDeposito}
        />
      </Modal>

      <Modal
        open={!!openModalRetiro}
        onClose={() => setOpenModalRetiro(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <RetiroForm
          tipoRetiro={openModalRetiro}
          balance={balance}
          setOpenModalRetiro={setOpenModalRetiro}
        />
      </Modal>

      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid xs={12} md={6}>
            <Item>
              <Typography
                variant="subtitle1"
                sx={{ color: "#4B4B4B", fontSize: 25 }}
              >
                Deposito
              </Typography>
              <List>
                <ListItemButton
                  onClick={() => handleModalToggleDeposito("usdt")}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <img src={Tether} alt="USDT" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Tether"
                    secondary="USDT"
                    sx={{
                      "& span": { fontSize: 25 },
                      "& p": { color: "#6A6A6A" },
                    }}
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => handleModalToggleDeposito("banco")}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <img src={Banco} alt="Banco" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Banco"
                    secondary="Cuenta Bancaria"
                    sx={{
                      "& span": { fontSize: 25 },
                      "& p": { color: "#6A6A6A" },
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  onClick={() => handleModalToggleDeposito("btc")}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <img src={Btc} alt="BTC" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Bitcoin"
                    secondary="BTC"
                    sx={{
                      "& span": { fontSize: 25 },
                      "& p": { color: "#6A6A6A" },
                    }}
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => handleModalToggleDeposito("eth")}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <img src={Eth} alt="ETH" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Ethereum"
                    secondary="ETH"
                    sx={{
                      "& span": { fontSize: 25 },
                      "& p": { color: "#6A6A6A" },
                    }}
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => handleModalToggleDeposito("bnb")}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <img src={Bnb} alt="BNB" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Binance Coin"
                    secondary="BNB"
                    sx={{
                      "& span": { fontSize: 25 },
                      "& p": { color: "#6A6A6A" },
                    }}
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => handleModalToggleDeposito("usdt_erc20")}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <img src={Tether} alt="USDT (ERC20)" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="USDT (ERC20)"
                    secondary="USDT"
                    sx={{
                      "& span": { fontSize: 25 },
                      "& p": { color: "#6A6A6A" },
                    }}
                  />
                </ListItemButton>
              </List>
            </Item>
          </Grid>

          <Grid xs={12} md={6}>
            <Item>
              <Typography
                variant="subtitle1"
                sx={{ color: "#4B4B4B", fontSize: 25 }}
              >
                Retiro
              </Typography>
              <List>
                <ListItemButton onClick={() => handleModalToggleRetiro("usdt")}>
                  <ListItemAvatar>
                    <Avatar>
                      <img src={Tether} alt="USDT" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Tether"
                    secondary="USDT"
                    sx={{
                      "& span": { fontSize: 25 },
                      "& p": { color: "#6A6A6A" },
                    }}
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => handleModalToggleRetiro("banco")}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <img src={Banco} alt="Banco" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Banco"
                    secondary="Cuenta Bancaria"
                    sx={{
                      "& span": { fontSize: 25 },
                      "& p": { color: "#6A6A6A" },
                    }}
                  />
                </ListItemButton>

                <ListItemButton onClick={() => handleModalToggleRetiro("btc")}>
                  <ListItemAvatar>
                    <Avatar>
                      <img src={Btc} alt="BTC" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Bitcoin"
                    secondary="BTC"
                    sx={{
                      "& span": { fontSize: 25 },
                      "& p": { color: "#6A6A6A" },
                    }}
                  />
                </ListItemButton>

                <ListItemButton onClick={() => handleModalToggleRetiro("eth")}>
                  <ListItemAvatar>
                    <Avatar>
                      <img src={Eth} alt="ETH" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Ethereum"
                    secondary="ETH"
                    sx={{
                      "& span": { fontSize: 25 },
                      "& p": { color: "#6A6A6A" },
                    }}
                  />
                </ListItemButton>

                <ListItemButton onClick={() => handleModalToggleRetiro("bnb")}>
                  <ListItemAvatar>
                    <Avatar>
                      <img src={Bnb} alt="BNB" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Binance Coin"
                    secondary="BNB"
                    sx={{
                      "& span": { fontSize: 25 },
                      "& p": { color: "#6A6A6A" },
                    }}
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => handleModalToggleRetiro("usdt_erc20")}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <img src={Tether} alt="USDT (ERC20)" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="USDT (ERC20)"
                    secondary="USDT"
                    sx={{
                      "& span": { fontSize: 25 },
                      "& p": { color: "#6A6A6A" },
                    }}
                  />
                </ListItemButton>
              </List>
            </Item>
          </Grid>
          <Grid xs={12} md={12} sx={{borderRadius: 10}}>
            <OperationTable />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Funds;
