import React, { useState, useCallback } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import axios from "axios";
import QrUSDT_TRC from "../../../../static/img/usdt_trc20.jpeg";
import QrBTC from "../../../../static/img/btc.jpeg";
import QrETH from "../../../../static/img/eth.jpeg";
import QrBNB from "../../../../static/img/bnb.jpeg";
import QrUSDT_ERC from "../../../../static/img/usdt_erc20.jpeg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "80%",
    md: 500,
  },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const AlertMessage = ({ alert }) =>
  alert.show && (
    <Box mt={2}>
      <Alert severity={alert.type}>{alert.message}</Alert>
    </Box>
  );

const QRCodeSection = ({ tipoDeposito }) => {
  switch (tipoDeposito) {
    case "usdt":
      return (
        <Box sx={{ textAlign: "center" }}>
          <img src={QrUSDT_TRC} alt="QR Wallet" style={{ width: "30%" }} />
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ color: "#1d575b", fontSize: 15, marginBottom: 4 }}
          >
            TZ6e8Mb1YtkKYAChVyMgkxJA5qbFFAVMz5
          </Typography>
        </Box>
      );
    case "btc":
      return (
        <Box sx={{ textAlign: "center" }}>
          <img src={QrBTC} alt="QR BTC" style={{ width: "30%" }} />
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ color: "#1d575b", fontSize: 15, marginBottom: 4 }}
          >
            bc1ajc5h5chpdagnm3wx9u2m2vvt4mky77js9m2ny8
          </Typography>
        </Box>
      );
    case "eth":
      return (
        <Box sx={{ textAlign: "center" }}>
          <img src={QrETH} alt="QR ETH, BNB, USDT" style={{ width: "30%" }} />
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ color: "#1d575b", fontSize: 15, marginBottom: 4 }}
          >
            0x09dF0111B108dF2bCDD55542BA6A4Cd91641cB4B
          </Typography>
        </Box>
      );
    case "bnb":
      return (
        <Box sx={{ textAlign: "center" }}>
          <img src={QrBNB} alt="QR ETH, BNB, USDT" style={{ width: "30%" }} />
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ color: "#1d575b", fontSize: 15, marginBottom: 4 }}
          >
            0x09dF0111B108dF2bCDD55542BA6A4Cd91641cB4B
          </Typography>
        </Box>
      );
    case "usdt_erc20":
      return (
        <Box sx={{ textAlign: "center" }}>
          <img
            src={QrUSDT_ERC}
            alt="QR ETH, BNB, USDT"
            style={{ width: "30%" }}
          />
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ color: "#1d575b", fontSize: 15, marginBottom: 4 }}
          >
            0x09dF0111B108dF2bCDD55542BA6A4Cd91641cB4B
          </Typography>
        </Box>
      );
    default:
      return null;
  }
};

export default function DepositoForm({ tipoDeposito, setOpenModalDeposito }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    amount: "",
    currency: "USD",
    type: { id: 1 },
    product: { id: 1 },
    user: { id: Cookies.get("id") },
    image: "",
    reference: "",
  });
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  const handleChange = useCallback((e) => {
    const { name, value, files } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      const formData = new FormData();

      let referenceText = "";

      if (tipoDeposito === "usdt") {
        referenceText = "USDT TRC-20: " + data.reference;
      } else if (tipoDeposito === "banco") {
        referenceText = "Número de referencia: " + data.reference;
      } else if (tipoDeposito === "btc") {
        referenceText = "BTC: " + data.reference;
      } else if (tipoDeposito === "eth") {
        referenceText = "ETH: " + data.reference;
      } else if (tipoDeposito === "bnb") {
        referenceText = "BNB: " + data.reference;
      } else if (tipoDeposito === "usdt_erc20") {
        referenceText = "USDT ERC-20: " + data.reference;
      }

      formData.append(
        "data",
        JSON.stringify({
          amount: data.amount,
          currency: data.currency,
          type: data.type.id,
          product: data.product.id,
          user: { id: data.user.id },
          reference: referenceText,
          date: new Date(),
        })
      );
      formData.append("files.image", data.image);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/operations`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwt")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200) {
          setAlert({
            show: true,
            type: "success",
            message: "Depósito enviado con éxito.",
          });
        }
      } catch (error) {
        setAlert({
          show: true,
          type: "error",
          message: "Error en el depósito.",
        });
      } finally {
        setLoading(false);
      }
    },
    [data]
  );

  return (
    <Box sx={style}>
      <IconButton
        onClick={() => setOpenModalDeposito(null)}
        sx={{ position: "absolute", top: 8, right: 8 }}
      >
        <CloseIcon />
      </IconButton>

      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{
          color: "#1d575b",
          fontSize: 25,
          fontWeight: "bold",
          textTransform: "uppercase",
        }}
      >
        Depósito {tipoDeposito}
      </Typography>

      <QRCodeSection tipoDeposito={tipoDeposito} />

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid md={12} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Monto"
              type="number"
              required
              name="amount"
              value={data.amount}
              onChange={handleChange}
            />
          </Grid>

          <Grid md={12} xs={12}>
            <label style={{ color: "#4B4B4B" }}>Comprobante</label>
            <TextField
              type="file"
              accept=".png,.jpg,.jpeg,.pdf"
              name="image"
              required
              onChange={handleChange}
            />
          </Grid>

          <Grid md={12} xs={12}>
            <label style={{ color: "#4B4B4B" }}>
              {["usdt", "btc", "eth", "bnb", "usdt_erc20"].includes(
                tipoDeposito
              )
                ? "Hash de la operación (Opcional)"
                : "Número de referencia"}
            </label>
            <TextField
              fullWidth
              variant="outlined"
              label={["usdt", "btc", "eth", "bnb", "usdt_erc20"].includes(tipoDeposito)
                ? "Hash"
                : "Referencia"}
              
              name="reference"
              value={data.reference}
              onChange={handleChange}
              multiline
            />
          </Grid>
        </Grid>

        <Box mt={2}>
          {!loading ? (
            <Button type="submit" variant="contained">
              Enviar
            </Button>
          ) : (
            <CircularProgress />
          )}
        </Box>

        <AlertMessage alert={alert} />
      </form>
    </Box>
  );
}
