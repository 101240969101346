import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ChartSaldoPartner = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Values",
        data: [],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    // Datos falsos para la gráfica (números en lugar de porcentajes)
    const fakeGlobalData = [
      { closing_date: "2024-01-31", value: 10 },
      { closing_date: "2024-02-28", value: 25 },
      { closing_date: "2024-03-31", value: 15 },
      { closing_date: "2024-04-30", value: 30 },
    ];

    const fakeIndividualData = [
      { closing_date: "2024-01-31", value: 5 },
      { closing_date: "2024-02-28", value: 10 },
      { closing_date: "2024-03-31", value: 20 },
      { closing_date: "2024-04-30", value: 25 },
    ];

    // Creamos un objeto para acumular los valores por mes (globales e individuales)
    const monthlyData = {};

    // Procesamos los valores globales
    fakeGlobalData.forEach((item) => {
      const monthYear = new Date(item.closing_date).toLocaleDateString("es-ES", {
        year: "numeric",
        month: "long",
      });
      const value = item.value;

      if (monthlyData[monthYear]) {
        monthlyData[monthYear] += value;
      } else {
        monthlyData[monthYear] = value;
      }
    });

    // Procesamos los valores individuales
    fakeIndividualData.forEach((item) => {
      const monthYear = new Date(item.closing_date).toLocaleDateString("es-ES", {
        year: "numeric",
        month: "long",
      });
      const value = item.value;

      if (monthlyData[monthYear]) {
        monthlyData[monthYear] += value;
      } else {
        monthlyData[monthYear] = value;
      }
    });

    // Ordenamos las etiquetas y datos
    const sortedLabels = Object.keys(monthlyData).sort(
      (a, b) => new Date(`${a} 01`).getTime() - new Date(`${b} 01`).getTime()
    );
    const sortedValues = sortedLabels.map((label) => monthlyData[label]);

    setChartData({
      labels: sortedLabels,
      datasets: [
        {
          label: "Valores por Mes",
          data: sortedValues,
          backgroundColor: "#ffb95f",
          borderColor: "#ffb95f",
          borderWidth: 1,
        },
      ],
    });
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: { position: "top", display: false },
      title: {
        display: false,
        text: "Valores Acumulados por Mes",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={chartData} options={options} width={100} height={44} />;
};

export default ChartSaldoPartner;
